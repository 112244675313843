<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "イベント備品レンタル倉庫 カリトク",
    meta: [
      { name: "description", content: "イベント備品レンタル倉庫 カリトク" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>
