import axios from "axios";
import store from "@/store";
class ApiClient {
  constructor() {
    this.axiosBase = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
    });
  }
  async get(
    endPoint,
    successCallback = null,
    failCallback = null,
    addHeader = true
  ) {
    let header = {
      headers: {
        contentType: "application/json",
      },
    };
    if (store.getters.token && addHeader) {
      header = {
        headers: {
          token: store.getters.token,
          contentType: "application/json",
        },
      };
    }
    const result = this.axiosBase
      .get(endPoint, header)
      .then((res) => {
        if (!this._resultParse(res)) return;
        if (successCallback) {
          successCallback(res);
        }
        return res.data.data;
      })
      .catch((err) => {
        if (failCallback) {
          failCallback(err);
        }
        alert("サーバーエラーが発生しました");
        return err;
      });
    return result;
  }

  async post(endPoint, postParam = null, addHeader = true) {
    store.commit("setErrors", "");
    let header = {
      headers: {},
    };
    if (addHeader)
      header = {
        headers: {
          token: store.getters.token,
          contentType: "application/json",
        },
      };

    const params = new URLSearchParams();
    let json = "";
    if (postParam) {
      json = JSON.stringify(postParam);
    } else if (store.getters.getPostParam) {
      json = JSON.stringify(store.getters.getPostParam);
    }
    params.append("data", json);
    const result = this.axiosBase
      .post(endPoint, params, header)
      .then((res) => {
        if (!this._resultParse(res)) {
          return;
        }
        store.commit("setErrors", res.data.data.errors);
        return res.data.data;
      })
      .catch((err) => {
        alert("サーバーエラーが発生しました");
        return err;
      });
    return result;
  }

  _resultParse(res) {
    if (res.data.data.state == 444) {
      return false;
    }
    if (res.data.data.state == 500) {
      alert("サーバーエラーが発生しました");
      return false;
    }
    store.commit("setToken", res.data.data._code);
    return true;
  }
}

export default ApiClient;
