<template lang="pug">
  .scene-list
    .inner
      sceneItem(
        icon="icon-scene-1.png"
        img="img-scene-1.jpg"
        ja="音楽祭"
        en="Pickup"
        url="/scene/festival/"
      )

      sceneItem(
        icon="icon-scene-2.png"
        img="img-scene-2.jpg"
        ja="お祭り"
        en="Pickup"
        url="/scene/festival/"
      )

      sceneItem(
        icon="icon-scene-3.png"
        img="img-scene-3.jpg"
        ja="餅つき大会"
        en="Pickup"
        url="/scene/festival/"
      )

      sceneItem(
        icon="icon-scene-4.png"
        img="img-scene-4.jpg"
        ja="運動会"
        en="Pickup"
        url="/scene/festival/"
      )

      sceneItem(
        icon="icon-scene-5.png"
        img="img-scene-5.jpg"
        ja="竣工式"
        en="Pickup"
        url="/scene/festival/"
      )

</template>

<script>
import sceneItem from "@/components/user/common/sceneItem.vue";

export default {
  name: "scene",
  components: {
    sceneItem,
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.scene-list {
  position: relative;
  z-index: 1;
  position: relative;
  overflow: scroll;
  box-sizing: border-box;
  @include mixin.liquidSize(padding-bottom, 6, 6);
  @include media.max-screen(media.$breakpoint-sm) {
    padding-left: calc(25 / 750 * 100vw);
  }
  @include media.min-screen(media.$breakpoint-md) {
    padding-left: calc(50% - 660px);
  }
  @media screen and (min-width: 769px) and (max-width: 1400px) {
    padding-left: 4.5vw;
  }
}
.inner {
  display: flex;
  flex-wrap: nowrap;
}
</style>
