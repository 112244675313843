<!-- ログイン時にユーザー画面・管理画面を行き来させるボタン -->
<template lang="pug">
  #switchMenu(v-if="is_login" :class="{active: is_active == true}")
    .inner
      router-link.switch-btn(to="/") ユーザー
      router-link.switch-btn(to="/admin/home") 管理

    .btn(:class="{active: is_active == true}" @click="is_active = !is_active")

</template>

<script>
import ApiClient from "@/lib/http.js";
export default {
  name: "SwitchMenu",
  data: function () {
    return {
      is_login: false,
      is_active: true,
    };
  },
  async created() {
    //ログイン状態取得
    const api = new ApiClient();
    let data = await api.get("/admin/check_login");
    this.is_login = data.is_login;
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
#switchMenu {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 10;

  width: 36px;
  height: 36px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  transition: all 0.4s ease-out;
  //background: #868383;
  border-radius: 18px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0;
  &.active {
    width: 180px;
    background: #fff;
    padding: 0 46px 0 10px;
    .inner {
      width: 100%;
      transition: all 0.4s ease-out;
    }
    .btn {
      //border-radius: 0%;
      transition: all 0.4s ease-out;
      &:before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }

  .btn {
    width: 36px;
    height: 36px;
    position: absolute;
    right: 0;
    top: 0;
    background: #868383;
    border-radius: 50%;
    transition: all 0.4s ease-out;
    cursor: pointer;
    &:before,
    &:after {
      content: "";
      background: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 1px;
      height: 12px;
      transition: all 0.2s ease-out;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }

  .inner {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 36px;
    overflow: hidden;
    transition: all 0.4s ease-out 0.4s;
  }

  .switch-btn {
    font-weight: 700; /* bold */
    text-align: center;
    margin: 0 auto;
    color: #44b9d0;
    white-space: nowrap;
  }
}
</style>
