<!-- 日英 横並び -->
<template lang="pug">
  .head-blk
    span.head(:class="{vertical: type=='v'}")
      span.ja {{ ja }}
      span.en {{ en }}
    p.desc(v-if="desc") {{ desc }}

</template>

<script>
export default {
  name: "head-1",
  props: {
    ja: String,
    en: String,
    desc: String,
    type: String,
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;

.head-blk {
  width: 100%;
  @include media.max-screen(media.$breakpoint-sm) {
    &:nth-of-type(7) {
      margin-right: auto;
    }
  }
  @include media.min-screen(media.$breakpoint-md) {
    display: flex;
    align-items: center;
  }
}

.head {
  display: flex;
  align-items: center;
  &.vertical {
    position: relative;
    .ja {
      margin-right: 0;
    }
    .en {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      text-align: center;
      transform: translate(0, 100%);
    }
  }
}
.ja {
  font-weight: 700; /* bold */
  @include mixin.liquidSize(font-size, 18, 27);
  display: inline-block;
  @include mixin.liquidSize(margin-right, 12, 17);
  @include mixin.lhFix(1);
}
.en {
  font-weight: 500; /* medium */
  color: #848484;
  @include mixin.liquidSize(font-size, 12, 15);
  @include mixin.lhFix(1);
}

.desc {
  font-weight: 500; /* medium */
  @include mixin.liquidSize(font-size, 15, 26);
  @include mixin.liquidSize(margin-left, 35, 0);
  @include mixin.lhFix(1);
}
</style>
