<!-- 日英 横並び -->
<template lang="pug">
  router-link.scene-list__item(:to="url")
    .img
      img(:src="loadImg")
    .inner
      span.icon
        img(:src="loadIcon")
      span.ja {{ ja }}
      span.en {{ en }}
      span.arrow
        img(src="@/assets/icon-arrow-1.svg")
</template>

<script>
export default {
  name: "sceneItem",
  props: {
    icon: String,
    img: String,
    ja: String,
    en: String,
    url: String,
  },
  computed: {
    loadImg() {
      return require("@/assets/" + this.img);
    },
    loadIcon() {
      return require("@/assets/" + this.icon);
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.scene-list__item {
  @include mixin.liquidSize(min-width, 232, 248);
  @include mixin.liquidSize(max-width, 232, 248);
  @include mixin.liquidSize(height, 387, 413);
  background: rgba(255, 255, 255, 0.94);
  @include mixin.liquidSize(border-radius, 6, 6);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  color: #000;
  position: relative;
  z-index: 2;
  border-radius: 6px;
  overflow: hidden;
  @include mixin.liquidSize(margin-right, 40, 40);
  @include media.min-screen(media.$breakpoint-md) {
    transition: all 0.4s ease-in-out 0.1s;
    &:hover {
      .img img {
        transform: scale(1.06);
      }
      .inner {
        transform: translate(-50%, 60%) scale(1.05);
        @include mixin.liquidSize(height, 203, 216);
      }
    }
  }
}

.img {
  width: 100%;
  @include mixin.liquidSize(height, 230, 246);
  overflow: hidden;
  position: relative;
  z-index: 1;
  img {
    transition: all 0.4s ease-in-out 0.1s;
    object-fit: contain;
  }
}

.inner {
  @include mixin.liquidSize(width, 190, 205);
  @include mixin.liquidSize(height, 183, 196);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mixin.liquidSize(border-radius, 6, 6);
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  box-sizing: border-box;
  background: #fff;
  @include mixin.liquidSize(padding-top, 17, 18);
  transition: all 0.4s ease-in-out 0.1s;
  transform: translate(-50%, 100%);
}

.icon {
  @include mixin.liquidSize(width, 50, 50);
  @include mixin.liquidSize(height, 50, 50);
  @include mixin.liquidSize(margin-bottom, 30, 30);
}

.ja {
  font-weight: 700; /* bold */
  @include mixin.liquidSize(font-size, 18, 19);
  @include mixin.liquidSize(margin-bottom, 3, 0);
}

.en {
  font-weight: 500; /* medium */
  color: #848484;
  @include mixin.liquidSize(font-size, 12, 13);
}

.arrow {
  @include mixin.liquidSize(width, 17, 17);
  @include mixin.liquidSize(height, 17, 17);
  @include mixin.liquidSize(margin-top, 25, 20);
}
</style>
